import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "next/image";
import { TRACK_LOGIN_SELECTED } from "../../../utils/trackEvent";
import { useStateContext } from "../../../context";
import { Dropdown } from "react-bootstrap";
import HeartIcon from "@heroicons/react/solid/HeartIcon";
import LogoutIcon from "@heroicons/react/solid/LogoutIcon";
import UserIcon from "@heroicons/react/solid/UserIcon";
import IdentificationIcon from "@heroicons/react/solid/IdentificationIcon";
import LoginDialog from "../LoginDialog";
import { useLazyQuery } from "@apollo/client";
import { TOKEN_REDIRECT_PANEL } from "../../../action/user";
import { useCookie } from "next-cookie";

const MenuModal = dynamic(() => import("./MenuModal"), { ssr: false });
const SearchModal = dynamic(() => import("./SearchModal"), { ssr: false });
const MenuIcon = dynamic(() => import("@heroicons/react/solid/MenuIcon"), {
  ssr: false,
});
const Button = dynamic(() => import("react-bootstrap/Button"), { ssr: false });

const HeaderSearch = (props) => {
  const { isLanding } = props;
  const router = useRouter();
  const token = useCookie().get("token");
  const { search } = router.query;
  const [state, setState] = useStateContext();
  const [searchInputValue, setSearchInputValue] = useState(search || "");
  const [suggestionModalOpen, setSuggestionModalOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);

  useEffect(() => storePathValues, [router.asPath]);

  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      window.open(
        `${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/masuk/${resp.token_redirect_panel}`
      );
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/listing-saya`);
      }
    },
    fetchPolicy: "no-cache",
  });

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  const onMenuClick = () => {
    setMenuModalOpen(true);
  };

  function handleLoginClick() {
    TRACK_LOGIN_SELECTED();

    setState({ ...state, loginModal: true });
  }

  const handleRedirecting = () => {
    getToken();
  };

  const user = state.user;

  const selectedLink = ["/"];

  return (
    <>
      <LoginDialog />

      <header>
        <div className={`px-0 ${!isLanding ? "bg-white shadow-sm" : ""}`}>
          <div className="container">
            <div className="py-2">
              <div className="d-flex gap-4 align-items-center justify-content-between">
                <Link href="/">
                  <a>
                    {/* <div className="d-none d-md-block"> */}
                    <Image
                      src="/logo_rmd_blue.png"
                      alt="logo rumahdewi"
                      width="200px"
                      height="60px"
                      quality={100}
                    />
                    {/* </div>
                    <div className="d-block d-md-none">
                      <Image
                        src={
                          selectedLink.includes(router.pathname)
                            ? "/logo_rmd.png"
                            : "/logo_rmd_blue.png"
                        }
                        alt="logo rumahdewi"
                        width="150px"
                        height="50px"
                        quality={100}
                      />
                    </div> */}
                  </a>
                </Link>

                <div className="d-none d-sm-flex gap-2">
                  {/* <Link href={`/project-baru`}>
                    <Button
                      variant="link"
                      className={`fw-bold text-decoration-none`}
                    >
                      Project Baru
                    </Button>
                  </Link> */}

                  <Link href={"https://blog.rumahdewi.com"}>
                    <Button
                      variant="link"
                      className={`fw-bold text-decoration-none text-secondary`}
                    >
                      Blog
                    </Button>
                  </Link>

                  <Link href={`/packages`}>
                    <Button
                      variant="link"
                      className={`fw-bold text-decoration-none text-secondary`}
                    >
                      Harga
                    </Button>
                  </Link>

                  <Link href={`/term-policy`}>
                    <Button
                      variant="link"
                      className={`fw-bold text-decoration-none text-secondary`}
                    >
                      Ketentuan
                    </Button>
                  </Link>

                  {!!user && token ? (
                    <>
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          variant="primary"
                          className="rounded-pill"
                        >
                          <i className="bi bi-person-fill"></i>{" "}
                          {user.first_name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <span
                              className="d-flex text-primary align-items-center gap-2"
                              onClick={handleRedirecting}
                            >
                              <UserIcon
                                width="20px"
                                height="20px"
                                style={{ marginBottom: ".1rem" }}
                              />{" "}
                              {user.first_name} {user.last_name}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <span
                              className="d-flex text-primary align-items-center gap-2"
                              onClick={handleRedirecting}
                            >
                              <IdentificationIcon
                                width="20px"
                                height="20px"
                                style={{ marginBottom: ".1rem" }}
                              />
                              Listing Saya
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <Link href="/wishlist">
                              <a className="d-flex align-items-center gap-2">
                                <HeartIcon
                                  width="20px"
                                  height="20px"
                                  style={{ marginBottom: ".1rem" }}
                                />{" "}
                                Wishlist
                              </a>
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link href="/logout" className="d-block">
                              <a className="d-flex align-items-center gap-2">
                                <LogoutIcon
                                  width="20px"
                                  height="20px"
                                  style={{ marginBottom: ".1rem" }}
                                />
                                Logout
                              </a>
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        className="rounded-pill"
                        onClick={handleLoginClick}
                      >
                        <div className="d-flex gap-2">
                          <i className="bi bi-box-arrow-in-left"></i>
                          <p className="mb-0">Masuk</p>
                        </div>
                      </Button>
                    </>
                  )}
                </div>

                <Button
                  onClick={onMenuClick}
                  variant="link"
                  className={`d-sm-none`}
                >
                  <MenuIcon width="25px" height="25px" />
                </Button>
              </div>
            </div>

            {suggestionModalOpen && (
              <SearchModal
                currentInput={searchInputValue}
                setCurrentInputSearchValue={setSearchInputValue}
                modalOpenStatus={suggestionModalOpen}
                setModalOpenStatus={setSuggestionModalOpen}
              />
            )}

            {menuModalOpen && (
              <MenuModal
                modalOpenStatus={menuModalOpen}
                setModalOpenStatus={setMenuModalOpen}
              />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderSearch;
